export const GOOGLE_ANALYTICS_EVENTS = {
  action: {
    click: 'clic',
    filter: 'Filtro',
    gallery: 'Galeria',
    interlink: 'Interlinkeado',
    location: 'Ubicación',
    noResults: 'Sin resultados',
    order: 'Ordenar',
    pricingLanding: 'Landings precio',
    pricingTendency: 'Tendencia de precio',
    searchboxTabClick: 'Click en pestaña',
    select: 'Elegir',
    showMore: 'Ver mas',
    iata: 'IATA',
    hmButton: 'hm-button',
    map: 'Mapa',
    delete: 'Eliminar',
    copy: 'Copiar',
    conditions: 'Condiciones',
    redeem: 'Canjear',
    question: 'Pregunta',
  },
  category: {
    flightsToDestination: 'Vuelos a destino',
    holidayPackagesResults: 'Resultados Hoteles: Buscador de Vacaciones',
    hotelsToDestination: 'Hoteles a destino',
    packagesToDestination: 'Paquetes a destino',
    searchbox: 'Buscador',
    suscription: 'Suscripción',
    landings: 'Landings',
    myAccountProfile: 'mi_cuenta :: perfil',
    myAccountBenefits: 'mi_cuenta :: beneficios',
  },
  label: {
    holidaySearch: 'Buscador de vacaciones',
    loadingFlights: 'Se cargaron solo {0} meses',
    loadingHotels: 'Se cargaron solo {0} meses',
    loadingPackages: 'Se cargaron solo {0} meses',
    noContent: 'No muestra contenido',
    NOK: 'NOK',    
    noResults: 'busqueda sin resultados',
    OK: 'OK',
    true: 'true',
    univeralAssitance: 'https://www.universal-assistance.com/cl-la/cocha',
    deleteStepOne: 'clic_boton_eliminar:paso_1',
    deleteStepTwo: 'clic_boton_continuar:paso_2',
    deleteStepFour: 'clic_boton_eliminar:paso_4',
  },
};