export const getProductCode = (icon: string): string => {
  if (!icon) {
    return '';
  }
  if (icon.includes('package')) {
    return 'P';
  }
  if (icon.includes('going')) {
    return 'V';
  }
  if (icon.includes('hotel')) {
    return 'H';
  }
  if (icon.includes('universal')) {
    return 'A';
  }
  return '';
}