import { Injectable } from '@angular/core';
import { GoogleAnalitycsEvent } from '../../core/model/google-analitycs/google-analytics-event.model';
import { GoogleAnalyticsPromotionEvent } from '../../core/model/google-analitycs/google-analytics-promotion-event.model';
import { getProductCode } from '../../core/utils/get-product-code.util';
import { Vertical } from 'src/app/core/enums/vertical.enum';
import { GOOGLE_ANALYTICS_EVENTS } from '../../core/constant/google-analytics-events.contant';
import { LandingElement } from 'src/app/core/model/landing-element.model';
import { LandingElementDeal } from 'src/app/core/model/landing-element-deal.model';
import { LandingConfiguration } from 'src/app/core/model/landing-configuration.model';


@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  private readonly allowedFiltersToAnalytics = ['price', 'month', 'amenities', 'stars', 'foods', 'name', 'baggage', 'stops', 'airlines', 'days', 'baggageQuantity', 'cabinType'];

  constructor() { }

  pushImpresions(landing: LandingConfiguration): void {
    landing.elements.forEach((element, index) => {
      if (element.type === 'title') {
        const parsedImpression = this.parseImpresionsSem(element, index);
        if (parsedImpression) {
          this.saveGoogleAnalitycsEvent('Load Impressions', { ecommerce: { impressions: parsedImpression } });
        }
      }
    });
  }

  private parseImpresionsSem(element: LandingElement, index: number): any {
    const impressions: any[] = [];
    element.deals.forEach((deal: LandingElementDeal, idx: number) => {
      const productIndex = 'product' + (1000 * (index + 1) + idx);
      const imp = this.parserDealSem(deal, productIndex);
      impressions.push(imp);
    });
    return impressions;
  }

  private parserDealSem(deal: LandingElementDeal, productIndex: string): any {
    const type = deal.productType || deal.type || '';
    switch (type) {
      case 'vuelo-hotel':
        return this.parseDealSemVueloHotel(deal, productIndex);
      case 'vuelo':
        return this.parseDealSemVuelo(deal, productIndex);
      case 'deeplink-circulo':
        return this.parseDealCaluga(deal, productIndex);
      case 'hotelweb':
        return this.parseDealSemHotelWeb(deal, productIndex);
      default:
        return this.parseDealOtros(deal, productIndex);
    }
  }


  private parseDealSemVueloHotel(deal: LandingElementDeal | any, productIndex: string): any {
    const destination = (deal.from.iata || '') + '|' + (deal.to.iata || '') + '|' + (deal.to.iata || '') + '|' + (deal.from.iata || '');
    const typeCabin = this.getCabinNameFromCode(deal.typeCabin || '-');
    const stars = (deal.items && deal.items.stars) ? deal.items.stars : '-';

    return {
      productIndex,
      id: `P|${destination}`,
      name: `P|-|${destination}`,
      category: 'Vuelo-Hotel',
      brand: '-|-',
      variant: `${typeCabin}|${stars}`,
      dimension12: '-',
      dimension13: '-',
      dimension14: deal.price,
      dimension15: '-'
    };
  }

  private parseDealSemVuelo(deal: LandingElementDeal | any, productIndex: string): any {
    let destination = (deal.from.iata || '') + '|' + (deal.to.iata || '');

    if (deal.searchConfig && deal.searchConfig.type && deal.searchConfig.type === 'round-trip') {
      destination += '|' + (deal.to.iata || '') + '|' + (deal.from.iata || '');
    }
    const typeCabin = this.getCabinNameFromCode(deal.typeCabin || '-');

    return {
      productIndex,
      id: `F|${destination}`,
      name: `F|-|${destination}`,
      category: 'Flights',
      brand: '-|-',
      variant: `${typeCabin}|-`,
      dimension12: '-',
      dimension13: '-',
      dimension14: deal.price,
      dimension15: '-'
    };
  }

  private parseDealCaluga(deal: LandingElementDeal | any, productIndex: string): any {
    return {
      productIndex,
      id: `C|${deal.title}`,
      name: `C|-|${deal.title}`,
      category: 'Caluga',
      brand: '-|-',
      variant: '-|-',
      dimension12: '-',
      dimension13: '-',
      dimension14: '-',
      dimension15: '-'
    };
  }

  private getCabinNameFromCode(code: string): string {
    switch (code.toLowerCase()) {
      case 'y':
        return 'Turista';
      case 's':
        return 'Premium Economy';
      case 'c':
        return 'Ejecutiva';
      case 'f':
        return 'Primera';
      default:
        return '-';
    }
  }

  private parseDealSemHotelWeb(deal: LandingElementDeal | any, productIndex: string): any {
    const stars = (deal.items && deal.items.stars) ? deal.items.stars : '-';

    return {
      productIndex,
      id: `H|${deal.destination}`, // + destination, // 'P|SCL|MAD|MAD|SCL',
      name: `H|-|${deal.destination}`, // + destination, // 'P|Hotel Juan Perez|SCL|MAD|MAD|SCL',
      category: 'Hotel',
      brand: '-|-',
      variant: `-|${stars}`,
      dimension12: '-',
      dimension13: '-',
      dimension14: deal.price,
      dimension15: '-' // no hay fechas
    };
  }

  private parseDealOtros(deal: LandingElementDeal | any, productIndex: string): any {
    return {
      productIndex,
      id: 'O|-',
      name: 'C|-|-',
      category: 'Otros',
      brand: '-|-',
      variant: '-|-',
      dimension12: '-',
      dimension13: '-',
      dimension14: '-',
      dimension15: '-'
    };
  }


  sendGoogleAnalitycsEvent({ category, label, action = undefined, nonInteraction = false }: any) {
    const event: GoogleAnalitycsEvent = {
      event_category: category,
      event_action: action,
      event_label: label.toString(),
      event_nonInteraction: nonInteraction
    };
    this.saveGoogleAnalitycsEvent('gaEvent', event);
  }

  sendPromotionEvent(deal: any, isFromInteraction: boolean = false): void {
    const promotion: GoogleAnalyticsPromotionEvent = {
      event: isFromInteraction ? 'eecPromotionClic' : 'eecPromotionImpression',
      promotion: {
        codigoProducto: getProductCode(deal?.icon),
        imagenCaluga: deal?.image || undefined,
        tipoCaluga: deal?.type || undefined,
        tituloCaluga: deal?.title || undefined
      }
    };
    this.saveGoogleAnalitycsEvent(promotion.event!, promotion);
  }


  private saveGoogleAnalitycsEvent(eventName: string, eventData: any): void {
    // if (['eecPromotionImpression', 'eecPromotionClic'].includes(eventData.event)) {
    //   dataLayer.push(eventData);
    // } else {
    //   const event: GoogleAnalitycsEvent = {
    //     event: eventData.event || 'gaEvent',
    //     event_category: eventData.event_category,
    //     event_action: eventData.event_action,
    //     event_label: eventData.event_label,
    //     event_value: eventData.event_value || undefined,
    //     event_nonInteraction: eventData.event_nonInteraction || false,
    //   };
    //   dataLayer.push(event);
    // }
  }

  sendSeoSemToGoogleAnalytics(category: string, label: string, action: string, filterName?: string, filterData?: any, nonInteraction?: boolean) {
    if (this.allowedFiltersToAnalytics.includes(filterName!)) {
      switch (filterName) {
        case 'price':
          label = `prices:${filterData?.options[0]}`;
          break;
        case 'baggage':
          label = `baggage:${filterData?.options[0]}`;
          break;
        case 'month':
          label = `Se cargaron solo ${filterData?.options.length} meses`;
          break;
        case 'stops':
          label = `connections:${filterData?.options.join('|') || filterData?.lastSelectedName || ''}`;
          break;
        case 'days':
          label = `Cantidad de días:${filterData?.options.join('|') || filterData?.lastSelectedName || ''}`;
          break;
        case 'baggageQuantity':
          label = `Equipaje:${filterData?.options.join('|') || filterData?.lastSelectedName || ''}`;
          break;
        case 'cabinType':
          label = `Cabina:${filterData?.options[0]}`;
          break;
        default:
          const filterString = filterData?.options.map(f => `${filterName}:${f}`);
          label = filterString?.join('|') || '';
          break;
      }
    }
    this.sendGoogleAnalitycsEvent({ category, label, action, nonInteraction });
  }

  getCategoryFromVertical(vertical?: string): string {
    switch (vertical) {
      case Vertical.FLIGHTS:
        return GOOGLE_ANALYTICS_EVENTS.category.flightsToDestination;
      case Vertical.PACKAGES:
        return GOOGLE_ANALYTICS_EVENTS.category.packagesToDestination;
      case Vertical.HOTELS:
        return GOOGLE_ANALYTICS_EVENTS.category.hotelsToDestination;
      default:
        return '';
    }
  }
}
